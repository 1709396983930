<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>


        
        <div class="xgyxbox" v-if="articlelist">

            <div class="newsitem" v-for="(item,index) in articlelist" :key="index">
                <router-link :to="{path:`/newsdetail/${item.id}`}">
                    <div class="newsitembox">
                        <div class="newsitemleft">
                            <div class="newstitle">{{item.title}}</div>
                            <div class="newstime">
                                <span>来源:</span>
                                <span v-if="item.infoSource.length <= 6">{{item.infoSource}}</span>
                                <span v-else>{{item.infoSource.slice(0,6)}}...</span>
                                <span>时间:</span><span>{{item.pushTime | dataFilter}}</span>
                            </div>
                        </div>
                        <div class="newsitemright">
                            <img :src="item.widePic">
                        </div>
                    </div>
                </router-link>
            </div>




        </div>

        <Foot />

        <div class="toGrzx" @click="toGrzx">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
        </div>


    </div>
</template>



<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getMovieArticle,getListColumn} from "@/api/index"
import store from '@/store'
import { Loading ,Message } from 'element-ui';
export default {
    components:{
        Foot
    },
    data () {
        return {
            headerflag : false,
            hiddenflag : false,
            articlelist:[],
            total:null,
            pageSize:8,
            currPageNo:1,
            articleload:true,
            t : null,
            clientHeight : null,
            scrollHeight : null,
            load : null,
            columnData:null,

        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            // if(this.$route.params.linkType && this.$route.params.linkType == 1){
            //     this.$router.push({name:'index'});
            // }else if(this.$route.params.linkType && this.$route.params.linkType == 2){
            //     this.$router.push({name:'movielist'});
            // }else{
            //     this.$router.push({name:'index'});
            // }
            this.$router.back(-1)
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
        this.headerflag = false
        this.hiddenflag = false
        var headericon = document.getElementById("headericon")
        var headerhidden = document.getElementById("headerhidden")
        //var headjt = document.getElementById("headjt")
        //var hiddenbox = document.getElementById("hiddenbox")
        headerhidden.style.top = "-100%"
        headericon.classList.remove("headericon2")
        headericon.classList.add("headericon")
        //hiddenbox.style.height = "0"
        //headjt.style.transform = "rotateZ(0deg)"
    },
        windowscroll(){
            this.t = document.documentElement.scrollTop || document.body.scrollTop;
            this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            var url = this.$route.path

            if(this.scrollHeight > this.clientHeight && this.t + this.clientHeight + 200  >= this.scrollHeight) {
                if(this.articleload && this.articlelist.length && this.articlelist.length < this.total){
                    if(!this.$route.query.articleId){
                        if(url.indexOf("bsxw") != -1){
                            this.showLoading()
                            this.articleload = false;
                            this.currPageNo += 1;
                            getMovieArticle({
                                "columnId":'1660566742605619202',
                                "pageNo": this.currPageNo,
                                "pageSize": this.pageSize
                            }).then(res => {
                                console.log(res)
                                this.articlelist = this.articlelist.concat(res.result.records)
                                this.total = res.result.total;
                                this.load && this.load.close();
                            })

                            setTimeout(() => {
                                this.articleload = true;
                            },3000)

                        }
                    }
                    
                }
                
            }
        },
        showLoading(){
            this.load && this.load.close(); 
            this.load = Loading.service({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
    },
    mounted(){
        
        this.showLoading()

        // let head = document.getElementsByTagName('head');
        // let meta = document.createElement('meta');
        // document.querySelector('meta[name="keywords"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // document.querySelector('meta[name="description"]').setAttribute('content', "新闻资讯-影视资讯-湖北引未来影视")
        // meta.content = {
        //     keywords:"新闻资讯-影视资讯-湖北引未来影视",
        //     description:"新闻资讯-影视资讯-湖北引未来影视",
        // };
        // head[0].appendChild(meta)
        // document.title = "新闻资讯-影视资讯-湖北引未来影视";


        this.t = document.documentElement.scrollTop || document.body.scrollTop;
        this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        window.addEventListener('scroll',this.windowscroll)


        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
            this.load && this.load.close();
        }else{
            getListColumn().then(res => {
                //console.log(res)
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)



            })
        }


        getMovieArticle({
            "columnId":'1660566742605619202',
            "pageNo": this.currPageNo,
            "pageSize": this.pageSize
        }).then(res => {
            console.log(res)
            this.articlelist = res.result.records;
            this.total = res.result.total;
            this.load && this.load.close();
        })


    },
    beforeDestroy (){
        window.removeEventListener('scroll',this.windowscroll)
    },
    filters:{
        dataFilter:function(val){
            if (!val) return ''
            return val.substring(0,10)
        }
    },
}
</script>




<style lang="scss" scoped>


.xgyxbox{
    width: 100%;
    padding: 0.2rem 0.22rem 0;
    box-sizing: border-box;
}
.xgyxbox .newsitem{
    width: 100%;
    height: 1.84rem;
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
    border-bottom: 0.02rem solid #F4F4F4;
    position: relative;
    .newsitembox{
        height: 1.84rem;
        display: flex;
        justify-content: space-between;
    }
}
.xgyxbox .newsitem .newsitemleft{
    position: relative;
    width: 4rem;
}
.xgyxbox .newsitem .newsitemleft .newstitle{
    width: 3.9rem;
    font-size: 0.26rem;
    font-weight: 400;
    line-height: 0.4rem;
    color: #343434;
    margin-bottom: 0.1rem;
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 3;    
    overflow: hidden;

}
.xgyxbox .newsitem .newsitemleft .newstime{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.32rem;
    color:#BABABA;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
}
.xgyxbox .newsitem .newsitemleft .newstime span{
    display: inline-block;
    transform: scale(.84);
    transform-origin:0 0; 
}
.newsitemright{
    width: 2.88rem;
    height: 100%;
    overflow: hidden;
    border-radius: 0.08rem;
}
.newsitemright img{
    width: 2.88rem;
    height: 100%;
}
.newsxian{
    width: 7rem;
    height: 0.02rem;
    background-color:#F4F4F4;
    margin: 0 auto 0.2rem;
}
</style>